import React from "react";
import { ToastProvider } from "react-toast-notifications";

import Navbar from "@layouts/Navbar";
import Footer from "@layouts/Footer";

import SEO from "@utils/seo";

import "@styles/global.scss";
import "@styles/utils.scss";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

const Layout = ({ navbar: navbarProps = {}, seo = {}, children }) => {
  return (
    <>
      <SEO {...seo} />
      <Navbar {...navbarProps} />
      <ToastProvider>
        <main className="layout layout--default">
          <div className="layout__inner">{children}</div>
        </main>
      </ToastProvider>
      <Footer />
    </>
  );
};

export default Layout;
