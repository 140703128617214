import React from "react";

function PhoneIcon(props) {
  return (
    <svg
      width={21}
      height={21}
      viewBox="0 0 21 21"
      fill="none"
      {...props}
    >
      <path
        d="M20.092 15.69v3a1.998 1.998 0 01-2.18 2 19.789 19.789 0 01-8.63-3.07 19.5 19.5 0 01-6-6 19.79 19.79 0 01-3.07-8.67A2 2 0 012.202.77h3a2 2 0 012 1.72c.126.96.361 1.904.7 2.81a2 2 0 01-.45 2.11l-1.27 1.27a16 16 0 006 6l1.27-1.27a2.001 2.001 0 012.11-.45c.907.34 1.85.574 2.81.7a2.001 2.001 0 011.72 2.03z"
        fill="#D82CD7"
      />
    </svg>
  );
}

export default PhoneIcon;
