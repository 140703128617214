import React from "react";
import classNames from "classnames";
import "./Button.styles.scss";

const Button = ({
  type = "primary",
  size = "medium",
  onClick = () => {},
  children,
  className,
  ...rest
}) => {
  return (
    <button
      className={classNames(
        "button",
        `button--${type}`,
        `button--${size}`,
        className,
      )}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
