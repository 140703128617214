import React from "react";
import classNames from "classnames";

import Button from "@components/Button";
import StaticImage from "@components/StaticImage";
import NavbarMobileLink from "./components/NavbarMobileLink";

import LogoIconWhite from "@components/svg/LogoIconWhite";
import PhoneIcon from "@components/svg/PhoneIcon";

import "./NavbarMobile.styles.scss";

const NavbarMobile = ({ links, isVisible, setIsMenuActive }) => {
  return (
    <div
      className={classNames("navbar-mobile", {
        "navbar-mobile--active": isVisible,
      })}
    >
      <div className="navbar-mobile__inner">
        <div className="navbar-mobile__header">
          <div className="navbar-mobile__logo">
            <StaticImage filename="logo-white.png" />
          </div>
        </div>
        <div className="navbar-mobile__body">
          <div className="navbar-mobile__links">
            {links?.map(item => {
              return <NavbarMobileLink {...item} />;
            })}
          </div>
          <div className="navbar-mobile__cta">
            <div className="navbar-mobile__cta__phone">
              <div className="navbar-mobile__cta__phone__icon">
                <PhoneIcon />
              </div>
              <div className="navbar-mobile__cta__phone__text">
                +123 467 789
              </div>
            </div>
            <Button type="primary">Call Us</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarMobile;
