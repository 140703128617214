import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import _ from "lodash";
import classNames from "classnames";
import {
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock";

import Button from "@components/Button";
import StaticImage from "@components/StaticImage";
import NavbarLink from "./components/NavbarLink";

import LogoIcon from "@components/svg/LogoIcon";
import PhoneIcon from "@components/svg/PhoneIcon";

import NavbarMobile from "@layouts/NavbarMobile";

import "./Navbar.styles.scss";

const Navbar = ({ Items: navbar, phone }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuActive, setIsMenuActive] = useState(false);

  useEffect(() => {
    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);

    isScrolled();
    window.addEventListener("scroll", isScrolled);
    window.addEventListener("resize", _.throttle(isScrolled, 150));

    return () => {
      window.removeEventListener(
        "resize",
        _.throttle(checkIfMobile, 150),
        true,
      );
      window.removeEventListener(
        "scroll",
        _.throttle(isScrolled, 150),
        true,
      );
      window.removeEventListener(
        "resize",
        _.throttle(isScrolled, 150),
      );
      enableBodyScroll();
    };
  }, []);

  const handleMenuTrigger = () => {
    if (!isMenuActive) {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }
    setIsMenuActive(!isMenuActive);
  };

  const isScrolled = () => {
    const scrolled = window.pageYOffset;

    if (scrolled > 0) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const checkIfMobile = () => {
    setIsMobile(document.body.clientWidth < 1041);
  };

  const navbarLinks =
    navbar?.map(item => {
      return {
        title: item?.name,
        to: item?.link,
        menu: (item?.Menu || [])?.map(item => {
          return {
            title: item?.name,
            to: item?.link,
          };
        }),
      };
    }) || [];

  return (
    <>
      <nav
        className={classNames("navbar", {
          "navbar--is-visible": isVisible,
          "navbar--menu-active": isMenuActive,
        })}
      >
        <div className="navbar__inner">
          <div className="navbar__logo">
            <Link to="/">
              <LogoIcon />
            </Link>
          </div>
          <div className="navbar__links">
            {navbarLinks?.map(item => {
              return <NavbarLink {...item} />;
            })}
          </div>
          <div className="navbar__cta">
            <div className="navbar__cta__phone">
              <div className="navbar__cta__phone__icon">
                <PhoneIcon />
              </div>
              <div className="navbar__cta__phone__text">{phone}</div>
            </div>
            <Button type="secondary">Call Us</Button>
          </div>
          <div
            className="navbar__hamburger"
            onClick={handleMenuTrigger}
          >
            <div
              className={classNames("hamburger hamburger--spin", {
                "is-active": isMenuActive,
              })}
            >
              <div className="hamburger-box">
                <div className="hamburger-inner"></div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {isMobile && (
        <NavbarMobile
          links={navbarLinks}
          isVisible={isMenuActive}
          setIsMenuActive={setIsMenuActive}
        />
      )}
    </>
  );
};

export default Navbar;
