/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
// import { useStaticQuery, graphql } from "gatsby";

function getBaseURL() {
  const url = process.env.GATSBY_URL;
  if (!url || url === "undefined") {
    // seriously
    return `http://localhost:8000`;
  }
  return url;
}

// const baseURL = getBaseURL();

function SEO({
  lang,
  meta,
  title = "",
  description = "",
  image,
  ...rest
}) {
  const metaTitle = title || "";
  const metaDescription = description || "";
  const metaImage = image || "";

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: rest.tags,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:creator`,
          content: "",
        },
        {
          name: `twitter:card`,
          content: "summary_large_image",
        },
        {
          name: `author`,
          content: "",
        },
        {
          name: `canonicalUrl`,
          content: "https://devscriptive.com",
        },
        {
          name: `url`,
          content: "https://devscriptive.com",
        },
        {
          name: `og-image`,
          content: metaImage,
        },
        {
          name: `twitter:image`,
          content: metaImage,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
