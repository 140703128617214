import React from "react";
import {
  FaTwitter,
  FaFacebookF,
  FaGoogle,
  FaInstagram,
} from "react-icons/fa";
import LogoIconWhite from "@components/svg/LogoIconWhite";
import StaticImage from "@components/StaticImage"

import "./Footer.styles.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__inner container">
        <div className="footer__left-side">
          <div className="footer__logo">
            <StaticImage filename="logo-white.png" />
          </div>
        </div>
        <div className="footer__links">
          
          <div className="footer__social">
            <FaTwitter />
            <FaFacebookF />
            <FaGoogle />
            <FaInstagram />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
