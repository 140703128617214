import React from "react";
import classNames from "classnames";
import { Link } from "gatsby";
import "./NavbarLink.styles.scss";

const NavbarLink = ({ to, title, menu = [] }) => {
  return (
    <div className="navbar-link">
      <Link
        to={to}
        className="navbar-link__element"
        activeClassName="navbar-link--active"
      >
        {title}
      </Link>
      {menu.length > 0 && (
        <div className={classNames("navbar-link__menu")}>
          <div className="navbar-link__menu__inner">
            {menu?.map(item => {
              return (
                <Link to={item?.to} className="navbar-menu-link">
                  {item?.title}
                </Link>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default NavbarLink;
